import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { request } from 'graphql-request';
import { Link } from 'react-scroll'

import './App.css';

import { Header } from './components/Header';
import { Aside } from './components/Aside';
import { MenuTop } from './components/MenuTop'
import { Footer } from './components/Footer';
import { FooterInfos } from './components/FooterInfos';

function App() {
  const [cardapios, setProducts] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const { cardapios } = await request(
        'https://sa-east-1.cdn.hygraph.com/content/clz7ox2zs06hn07wdwpaykano/master',
        `
      {
        cardapios(orderBy: order_ASC, first: 100) {
          id
          title
          description
          price
          photo {
            url
          }
          categoria {
            title
            slug
          }
        }
      }
    `
      );

      setProducts(cardapios);
    };
      console.log(cardapios)
    fetchProducts();
  }, []);

  return (
    <div id='home' className="flex min-h-screen flex-col items-center w-full">
      <Header />
      <main className="flex flex-col items-start justify-center px-2 py-2 mt-14 w-full lg:flex-row lg:w-[1020px] lg:py-6">
        <Aside />
        <div className='flex flex-col w-full'>
          <MenuTop />
          <div className="px-1">
            <Router>
              {!cardapios ? (
                <div className='flex items-center'>
                  <img src="/images/loading.svg" alt="Loading" />
                  <p className='text-xs md:text-base'>Carregando...</p>
                </div>
              ) : (
                <React.Fragment>
                  <h2 id='carnes' className='text-2xl uppercase font-bold my-2 mx-1'>Carnes <span className='text-xl'>*</span></h2>
                  {cardapios.map(item => {
                    if (item.categoria.title === 'Carnes') {
                      return (
                        <div key={item.id} className='flex bg-white justify-between shadow-md rounded-md mb-4 w-full'>
                          <div className='flex flex-col justify-between p-2 py-2 lg:p-4'>
                            <h2 className='text-base font-bold md:text-lg'>{item.title}</h2>
                            <p className='text-sm md:text-base'>{item.description}</p>
                            <h2 className='text-sm font-bold text-[#4d4d4d]'>R$ {item.price}</h2>
                          </div>
                          <img src={item.photo ? item.photo.url : '/images/Sem-Foto.png'} alt={item.title} className='w-[140px] rounded-tr-lg rounded-br-lg' />
                        </div>
                      )
                    }
                  })}

                  <p className='mb-5'>* Estes produtos contém alta concentração de sódio.</p>

                  <hr class="h-[2px] mx-auto my-4 bg-slate-400/25 border-0 rounded md:my-8 dark:bg-slate-400/25" />

                  <h2 id='entradas' className='text-2xl uppercase font-bold my-2 mx-1'>Entradas</h2>
                  {                    
                    cardapios.map(item => {                      
                      if (item.categoria.title === 'Entradas') {
                        return (
                          <div key={item.id} className='flex bg-white justify-between shadow-md rounded-md mb-4 w-full'>
                            <div className='flex flex-col justify-between p-2 py-2 lg:p-4'>
                              <h2 className='text-base font-bold md:text-lg'>{item.title}</h2>
                              <p className='text-sm md:text-base'>{item.description}</p>
                              <h2 className='text-sm font-bold text-[#4d4d4d]'>R$ {item.price}</h2>
                            </div>
                            <img src={item.photo ? item.photo.url : '/images/Sem-Foto.png'} alt={item.title} className='w-[140px] rounded-tr-lg rounded-br-lg' />
                          </div>
                        )
                      }
                    }
                    )
                  }

                  <hr class="h-[2px] mx-auto my-4 bg-slate-400/25 border-0 rounded md:my-8 dark:bg-slate-400/25" />

                  <h2 id='porcoes' className='text-2xl uppercase font-bold my-2' mx-1>Porções</h2>
                  {
                    cardapios.map(item => {
                      if (item.categoria.title === 'Porções') {
                        return (
                          <div key={item.id} className='flex bg-white justify-between shadow-md rounded-md mb-4 w-full'>
                            <div className='flex flex-col justify-between p-2 py-2 lg:p-4'>
                              <h2 className='text-base font-bold md:text-lg'>{item.title}</h2>
                              <p className='text-sm md:text-base'>{item.description}</p>
                              <h2 className='text-sm font-bold text-[#4d4d4d]'>R$ {item.price}</h2>
                            </div>
                            <img src={item.photo ? item.photo.url : '/images/Sem-Foto.png'} alt={item.title} className='w-[140px] rounded-tr-lg rounded-br-lg' />
                          </div>
                        )
                      }
                    }
                    )
                  }

                  <hr class="h-[2px] mx-auto my-4 bg-slate-400/25 border-0 rounded md:my-8 dark:bg-slate-400/25" />

                  <h2 id='acompanhamentos' className='text-2xl uppercase font-bold my-2 mx-1'>Acompanhamentos</h2>
                  {
                    cardapios.map(item => {
                      if (item.categoria.title === 'Acompanhamentos') {
                        return (
                          <div key={item.id} className='flex bg-white justify-between shadow-md rounded-md mb-4 w-full'>
                            <div className='flex flex-col justify-between p-2 py-2 lg:p-4'>
                              <h2 className='text-base font-bold md:text-lg'>{item.title}</h2>
                              <p className='text-sm md:text-base'>{item.description}</p>
                              <h2 className='text-sm font-bold text-[#4d4d4d]'>R$ {item.price}</h2>
                            </div>
                            <img src={item.photo ? item.photo.url : '/images/Sem-Foto.png'} alt={item.title} className='w-[140px] rounded-tr-lg rounded-br-lg' />
                          </div>
                        )
                      }
                    }
                    )
                  }

                  <hr class="h-[2px] mx-auto my-4 bg-slate-400/25 border-0 rounded md:my-8 dark:bg-slate-400/25" />

                  <h2 id='bebidas' className='text-2xl uppercase font-bold my-2 mx-1'>Bebidas</h2>
                  {
                    cardapios.map(item => {
                      if (item.categoria.title === 'Bebidas') {
                        return (
                          <div key={item.id} className='flex bg-white justify-between shadow-md rounded-md mb-4 w-full'>
                            <div className='flex flex-col justify-between p-2 py-2 lg:p-4'>
                              <h2 className='text-base font-bold md:text-lg'>{item.title}</h2>
                              <p className='text-sm md:text-base'>{item.description}</p>
                              <h2 className='text-sm font-bold text-[#4d4d4d]'>R$ {item.price}</h2>
                            </div>
                            <img src={item.photo ? item.photo.url : '/images/Sem-Foto.png'} alt={item.title} className='w-[140px] rounded-tr-lg rounded-br-lg' />
                          </div>
                        )
                      }
                    }
                    )
                  }

                  <hr class="h-[2px] mx-auto my-4 bg-slate-400/25 border-0 rounded md:my-8 dark:bg-slate-400/25" />

                  <h2 id='sobremesas' className='text-2xl  uppercase font-bold my-2 mx-1'>Sobremesas</h2>
                  {
                    cardapios.map(item => {
                      if (item.categoria.title === 'Sobremesas') {
                        return (
                          <div key={item.id} className='flex bg-white justify-between shadow-md rounded-md mb-4 w-full'>
                            <div className='flex flex-col justify-between p-2 py-2 lg:p-4'>
                              <h2 className='text-base font-bold md:text-lg'>{item.title}</h2>
                              <p className='text-sm md:text-base'>{item.description}</p>
                              <h2 className='text-sm font-bold text-[#4d4d4d]'>R$ {item.price}</h2>
                            </div>
                            <img src={item.photo ? item.photo.url : '/images/Sem-Foto.png'} alt={item.title} className='w-[140px] rounded-tr-lg rounded-br-lg' />
                          </div>
                        )
                      }
                    }
                    )
                  }
                  <hr class="h-[2px] mx-auto my-4 bg-slate-400/25 border-0 rounded md:my-8 dark:bg-slate-400/25" />
                  <p>Imagens meramente ilustrativas.</p>
                </React.Fragment>
              )}
            </Router>
          </div>
          <FooterInfos />
        </div>
      </main>

      <Link to="home" spy={true} smooth={true} offset={0} duration={500}>
        <button className=' bg-[#CC3333] p-2 bottom-4 right-4 opacity-90 fixed border-none rounded-full hover:bg-[#A72525] transition-all'><img src="/images/up-arrow.png" alt="to top" /></button>
      </Link>

      <Footer />
    </div>
  );
}

export default App;
